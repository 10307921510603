import React, { useContext, useEffect, useState } from 'react';
import axios from '../../../../../../../axiosInstance';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import clsx from 'clsx';
import WindowsIcon from '../../../../../../../images/cloudPilot/windows-icon.svg';
import Select from 'react-select';
import LinuxIcon from '../../../../../../../images/cloudPilot/linux-icon.svg';
import { cloudId } from '../../../../constants';
import { Input, Label } from 'reactstrap';

const NamingAndOs = ({ deviceEntity, changeDeviceEntityHandler }) => {
  const [templates, setTemplates] = useState({});
  const [selectedType, setSelectedType] = React.useState('windows');
  const [selectedWindows, setSelectedWindows] = React.useState({});
  const [selectedLinux, setSelectedLinux] = React.useState({});

  const { tenant } = useContext(AuthContext);

  const getTemplates = () => {
    axios
      .get(`/api/user/device/templates?tenant=${tenant}&cloudId=${cloudId}`)
      .then(res => {
        if (res && res.data) {
          setTemplates(res.data);
        }
      })
      .catch(e => console.warn(e.message));
  };

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (deviceEntity && templates?.templates_windows?.length > 0) {
      const templateWindows = templates.templates_windows.find(template => template.id === deviceEntity.templateId);

      setSelectedWindows({
        key: templateWindows ? templateWindows.id : templates.templates_windows[0].id,
        label: templateWindows ? templateWindows.name : templates.templates_windows[0].name,
        value: templateWindows ? templateWindows.id : templates.templates_windows[0].id,
      });
    }
    if (deviceEntity && templates?.templates_linux?.length > 0) {
      const templateLinux = templates.templates_linux.find(template => template.id === deviceEntity.templateId);

      setSelectedLinux({
        key: templateLinux ? templateLinux.id : templates.templates_linux[0].id,
        label: templateLinux ? templateLinux.name : templates.templates_linux[0].name,
        value: templateLinux ? templateLinux.id : templates.templates_linux[0].id,
      });
    }
  }, [templates, deviceEntity.templateId]);

  return (
    <div className="architecture-form-block flex w-100 rounded bg-white">
      <div className="architecture-form-block-header p-3">
        <p className="font-size-lg bold mb-0">Naming and OS</p>
      </div>
      <div className="p-4">
        <div className="row">
          <div className="col-6 pr-2">
            <div className="architecture-form-shadow">
              <div
                className={clsx(
                  'd-flex align-items-center bold p-2',
                  selectedType === 'windows' ? 'bg-soft-blue' : 'bg-gray'
                )}
                onClick={() => setSelectedType('windows')}
              >
                <img src={WindowsIcon} alt="windows-icon" />
                <p className={clsx('ml-2 mb-0', selectedType === 'windows' ? 'text-blue-dark' : 'text-gray')}>
                  Windows
                </p>
              </div>
              <Select
                className="selector-no-border"
                options={
                  templates.templates_windows &&
                  templates.templates_windows.map(template => ({
                    key: template.id,
                    label: template.name,
                    value: template.id,
                  }))
                }
                onChange={e => {
                  setSelectedType('windows');
                  changeDeviceEntityHandler('templateId', e.value);
                }}
                value={selectedWindows}
              />
            </div>
          </div>
          <div className="col-6 pl-2">
            <div className="architecture-form-shadow">
              <div
                className={clsx(
                  'd-flex align-items-center bold p-2',
                  selectedType === 'linux' ? 'bg-soft-blue' : 'bg-gray'
                )}
                onClick={() => setSelectedType('linux')}
              >
                <img src={LinuxIcon} alt="linux-icon" />
                <p className={clsx('ml-2 mb-0', selectedType === 'linux' ? 'text-blue-dark' : 'text-gray')}>Linux</p>
              </div>
              <Select
                className="selector-no-border"
                options={
                  templates.templates_linux &&
                  templates.templates_linux.map(template => ({
                    key: template.id,
                    label: template.name,
                    value: template.id,
                  }))
                }
                onChange={e => {
                  setSelectedType('linux');
                  changeDeviceEntityHandler('templateId', e.value);
                }}
                value={selectedLinux}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 pr-2">
            <Label for="hostname" className="bold">
              Display name
            </Label>
            <Input
              id="dislpayname"
              data-cy="dislpayname"
              className="w-100"
              type="text"
              placeholder="Dislpay name"
              value={deviceEntity.name}
              onChange={e => changeDeviceEntityHandler('name', e.target.value)}
            />
          </div>
          <div className="col-6 pl-2">
            <Label for="hostname" className="bold">
              Host name
            </Label>
            <Input
              id="hostname"
              data-cy="hostname"
              className="w-100"
              type="text"
              placeholder="Host name"
              value={deviceEntity.hostname}
              onChange={e => changeDeviceEntityHandler('hostname', e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NamingAndOs;
