import React, { useEffect, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import ButtonWithDeleteModal from '../../UI/ButtonWithDeleteModal';
import UpgradeK8sVersionModal from './UpgradeK8sVersionModal';
import Skeleton from 'react-loading-skeleton';
import MultiInput from '../../UI/MultiInput';
import { updateAllowedIPs } from '../../../utils/kubernetes';
import clsx from 'clsx';

const ClusterMain = ({
  clusterInfo,
  fetchingClusterInfo,
  clusterIdentifier,
  upgradeVersion,
  downloadConfig,
  setUpgradeTalosVersionModalOpen,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [initialIps, setInitialIps] = useState([]);
  const [isSetInitialIps, setIsSetInitialIps] = useState(false);
  const [ips, setIps] = useState([]);
  const [isMultiInputChanged, setIsMultiInputChanged] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIps(clusterInfo.talosApiAllowedIps);

    if (clusterInfo.talosApiAllowedIps && !isSetInitialIps) {
      setInitialIps(clusterInfo.talosApiAllowedIps);
      setIsSetInitialIps(true);
    }
  }, [clusterInfo.talosApiAllowedIps]);

  useEffect(() => {
    if (!isMultiInputChanged) {
      setIsDisabled(JSON.stringify(ips) === JSON.stringify(initialIps));
    } else {
      setIsDisabled(false);
    }
  }, [ips, isMultiInputChanged]);

  const handleStatusConversion = status => {
    if (status === 'Created' || status === 'Deployed') {
      return (
        <div>
          <span className="badge sm badge-warning inherited">Provisioning</span>
        </div>
      );
    }
    if (status === 'Ready') {
      return (
        <div>
          <span className="badge sm badge-success">Ready</span>
        </div>
      );
    }
    if (status === 'Error') {
      return (
        <div>
          <span className="badge sm badge-danger">Error</span>
        </div>
      );
    }
    if (status === 'Deleting') {
      return (
        <div>
          <span className="badge sm badge-danger">Deleting</span>
        </div>
      );
    }
    return <div className="loading-circle rotating" />;
  };

  return (
    <>
      <Card className="mb-4">
        <CardBody className="d-flex flex-row justify-content-between card-header-low-border">
          <h3 className="mb-0" style={{ fontSize: '1.125rem' }}>
            <i className="fas fa-dharmachakra mr-2" />
            Cluster info
          </h3>
          <ButtonWithDeleteModal
            dataCy="destroy-talos-cluster"
            url={`api/user/kubernetes-talos/delete-cluster/${clusterIdentifier}`}
            entity="Talos Cluster"
            className="btn-sm btn-pink"
            name={clusterInfo.clusterName}
            successredirecturl="/kubernetes-talos"
          >
            <span>Destroy</span>
          </ButtonWithDeleteModal>
        </CardBody>
        <CardBody className="px-0">
          {!fetchingClusterInfo && clusterInfo.clusterName !== 'Unknown' ? (
            <>
              <Table borderless size="sm" className="mb-0">
                <tbody className="table-left-columns wide">
                  <tr>
                    <td>Current state</td>
                    <td>{handleStatusConversion(clusterInfo.clusterStatus)}</td>
                  </tr>
                  <tr>
                    <td>Talos Config</td>
                    <td>
                      <button className="btn btn-link p-0 text-blue-dark cursor-pointer">
                        <u onClick={() => downloadConfig('talos')}>Download config</u>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Talos API Endpoint</td>
                    <td>{clusterInfo.talosApiEndpoint}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Talos Version</td>
                    <td>{clusterInfo.talosVersion}</td>
                    <td className="d-flex justify-content-end">
                      {/*<button*/}
                      {/*  className="btn btn-sm btn-success mr-3 update-button"*/}
                      {/*  onClick={() => setUpgradeTalosVersionModalOpen(true)}*/}
                      {/*>*/}
                      {/*  Upgrade*/}
                      {/*</button>*/}
                    </td>
                  </tr>
                  <tr className="tr-full-width">
                    <td>Talos API Allowed IPs</td>
                    <td></td>
                    <td className="d-flex justify-content-end">
                      <button
                        className={clsx(
                          'btn btn-sm btn-success mr-3 update-button',
                          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                        )}
                        onClick={() => updateAllowedIPs(clusterIdentifier, 'talos', ips, setIsDisabled)}
                        disabled={isDisabled}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pt-2 pl-4 pr-4">
                <MultiInput
                  wrapperClassName="mb-0"
                  id="k8s-allowed-ip1"
                  dataCy="k8s-allowed-ip"
                  value={ips}
                  onChange={setIps}
                  placeholder="Leave empty for access from any IP or enter a single IP address or a range"
                  setIsMultiInputChanged={setIsMultiInputChanged}
                />
              </div>
            </>
          ) : (
            <div className="pl-2 pr-2">
              <Skeleton height={24} count={10} />
            </div>
          )}
        </CardBody>
      </Card>
      {modalOpen && (
        <UpgradeK8sVersionModal
          itemName="Kubernetes"
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          toggleModal={() => {
            setModalOpen(false);
          }}
          kubernetesCurrentVersion={clusterInfo}
          versionOptions={clusterInfo}
          upgradeVersion={upgradeVersion}
        />
      )}
    </>
  );
};

export default ClusterMain;
