import React, { useContext, useEffect, useState } from 'react';
import SelectedEntity from './formComponents/SelectedEntity';
import NamingAndOs from './formComponents/Device/NamingAndOs';
import Hardware from './formComponents/Device/Hardware';
import Security from './formComponents/Device/Security';
import EntityKeyInput from './formComponents/EntityKeyInput';
import InboundRules from './formComponents/Firewall/InboundRules';
import OutboundRules from './formComponents/Firewall/OutboundRules';
import { Button } from 'reactstrap';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import axios from '../../../../../axiosInstance';
import Connections from './formComponents/Connections';

const FirewallForm = () => {
  const [firewallEntity, setFirewallEntity] = useState({
    id: 1,
    cloudId: 9,
    name: 'My firewall 1',
    assignToNetworkId: 1,
    internalIp: '',
    inboundRules: [
      {
        source: '192.168.1.1',
        destination: '192.168.1.1',
        service: 'https',
        protocol: 'tcp',
        portRange: '80',
      },
    ],
    outboundRules: [
      {
        source: '192.168.1.1',
        destination: '192.168.1.1',
        service: 'https',
        protocol: 'tcp',
        portRange: '80',
      },
    ],
    ipsecRules: [
      {
        phase1: [
          {
            remoteGateway: '192.168.1.1',
            hashAlgorithm: 'sha1',
            dhGroup: 2,
            preSharedKey: 'password',
            dhLifetime: 28800,
          },
        ],
        phase2: [
          {
            localNetwork: '192.168.1.1/28',
            remoteLanNetwork: '192.168.1.1/28',
            encryption: 'aes256',
            hashAlgorithm: 'sha1',
            pfsGroup: 2,
            pfsLifetime: 3600,
          },
        ],
      },
    ],
  });
  const [isFirewallEntityReady, setIsFirewallEntityReady] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { entityData, setIsEntityChanged, fetchDetails, setIsEntityOpen } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  useEffect(() => {
    if (entityData) {
      setFirewallEntity({
        name: entityData?.data?.name || 'My firewall',
        assignToNetworkId: entityData?.data?.assignToNetworkId || null,
        internalIp: entityData?.data?.internalIp || '',
        inboundRules: entityData?.data?.inboundRules || [],
        outboundRules: entityData?.data?.outboundRules || [],
        ipsecRules: entityData?.data?.ipsecRules || [],
      });
      setIsFirewallEntityReady(true);
    }
  }, [entityData]);

  const changeFirewallEntityHandler = (key, value) => {
    const correctKey = key === 'Ip' ? 'internalIp' : key;

    setFirewallEntity({
      ...firewallEntity,
      [correctKey]: value,
    });
  };

  const changeInboundRuleHandler = (index, key, value) => {
    const newRules = [...firewallEntity.inboundRules];
    newRules[index][key] = value;
    changeFirewallEntityHandler('inboundRules', newRules);
  };

  const changeOutboundRuleHandler = (index, key, value) => {
    const newRules = [...firewallEntity.outboundRules];
    newRules[index][key] = value;
    changeFirewallEntityHandler('outboundRules', newRules);
  };

  const addInboundRuleHandler = () => {
    const newRules = [...firewallEntity.inboundRules];
    newRules.push({ source: '', destination: '', service: '', protocol: '', portRange: '' });
    changeFirewallEntityHandler('inboundRules', newRules);
  };

  const addOutboundRuleHandler = () => {
    const newRules = [...firewallEntity.outboundRules];
    newRules.push({ source: '', destination: '', service: '', protocol: '', portRange: '' });
    changeFirewallEntityHandler('outboundRules', newRules);
  };

  const addIpSecRuleHandler = () => {
    const newRules = [...firewallEntity.ipsecRules];
    newRules.push({
      phase1: [
        {
          remoteGateway: '',
          hashAlgorithm: 'sha1',
          dhGroup: 2,
          preSharedKey: 'password',
          dhLifetime: 28800,
        },
      ],
      phase2: [
        {
          localNetwork: '',
          remoteLanNetwork: '',
          encryption: 'aes256',
          hashAlgorithm: 'sha1',
          pfsGroup: 2,
          pfsLifetime: 3600,
        },
      ],
    });
    changeFirewallEntityHandler('ipsecRules', newRules);
  };

  const updateScriptHandler = async () => {
    setIsUpdating(true);

    axios
      .put(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}`, { type: 'firewall', data: firewallEntity })
      .then(res => {
        setIsUpdating(false);
        setIsEntityChanged(false);
        setIsEntityOpen(false);
        fetchDetails();
      })
      .catch(e => {
        setIsUpdating(false);
        console.warn(e.message);
      });
  };

  return (
    <div className="d-flex flex-column full-height">
      {isFirewallEntityReady ? (
        <div>
          <SelectedEntity />
          <Connections />
          <EntityKeyInput
            type="Firewall"
            inputKey="name"
            entity={firewallEntity}
            changeEntityHandler={changeFirewallEntityHandler}
          />
          <EntityKeyInput
            type="Firewall"
            inputKey="Ip"
            entity={firewallEntity}
            changeEntityHandler={changeFirewallEntityHandler}
          />
          <InboundRules
            firewallEntity={firewallEntity}
            changeFirewallEntityHandler={changeFirewallEntityHandler}
            changeInboundRuleHandler={changeInboundRuleHandler}
            addInboundRuleHandler={addInboundRuleHandler}
          />
          <OutboundRules
            firewallEntity={firewallEntity}
            changeFirewallEntityHandler={changeFirewallEntityHandler}
            changeOutboundRuleHandler={changeOutboundRuleHandler}
            addOutboundRuleHandler={addOutboundRuleHandler}
          />
        </div>
      ) : (
        <span>Loading...</span>
      )}
      <div className="architecture-sticky-button-wrapper mt-3">
        <Button className="btn-violet w-100" onClick={() => updateScriptHandler()} disabled={isUpdating}>
          {isUpdating && (
            <span className="spinner-border spinner-border-sm mb-1 mr-2" role="status" aria-hidden="true" />
          )}
          Update script
        </Button>
      </div>
    </div>
  );
};

export default FirewallForm;
