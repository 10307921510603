import React, { useContext, useEffect, useState } from 'react';
import SelectedEntity from './formComponents/SelectedEntity';
import SubnetSize from './formComponents/Network/SubnetSize';
import { cloudId } from '../../constants';
import axios from '../../../../../axiosInstance';
import { defaultNetworkSpeed } from '../../../../../utils/constants';
import NetworkSpeed from './formComponents/Network/NetworkSpeed';
import { Button } from 'reactstrap';
import WanName from './formComponents/Network/WanName';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import { useParams } from 'react-router-dom';
import { PushNotificationContext } from '../../../../contexts/PushNotificationContext';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../../../../contexts/AuthContext';
import Connections from './formComponents/Connections';

const WanForm = () => {
  const [wanEntity, setWanEntity] = useState({});
  const [networkSpeeds, setNetworkSpeeds] = useState([]);
  const [isWanEntityReady, setIsWanEntityReady] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const controller = new AbortController();
  const { entityData, setIsEntityChanged, setIsEntityOpen, fetchDetails } = useContext(ArchitectureContext);
  const { pushNotification } = useContext(PushNotificationContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  useEffect(() => {
    if (entityData) {
      setWanEntity({
        name: entityData?.data?.name || '',
        subnetSize: entityData?.data?.subnetSize || 29,
        networkSpeed: entityData?.data?.currentSpeed || '10000000000',
      });
      setIsWanEntityReady(true);
    }
  }, [entityData]);

  const fetchNetworkSpeeds = () => {
    const payload = {
      cloudId,
      type: 'WAN',
      is_stretched: false,
    };
    axios.post('/api/user/network-speeds', payload).then(res => {
      setNetworkSpeeds(res.data);
      const speedValue =
        res.data.find(obj => obj['value'] === defaultNetworkSpeed.WAN)?.value ??
        Math.min(...res.data.map(obj => obj.value));
      changeWanEntityHandler('networkSpeed', speedValue, false);
    });
  };

  useEffect(() => {
    if (isWanEntityReady) {
      fetchNetworkSpeeds();
    }
  }, [isWanEntityReady]);

  const changeWanEntityHandler = (key, value, isEntityChanged = true) => {
    if (isEntityChanged) {
      setIsEntityChanged(true);
    }
    const wanEntityCopy = { ...wanEntity };
    wanEntityCopy[key] = value;
    setWanEntity(wanEntityCopy);
  };

  const updateScriptHandler = async () => {
    setIsUpdating(true);

    axios
      .put(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}`, { type: 'wan', data: wanEntity })
      .then(res => {
        setIsUpdating(false);
        setIsEntityChanged(false);
        setIsEntityOpen(false);
        fetchDetails();
      })
      .catch(e => {
        setIsUpdating(false);
        console.warn(e.message);
      });
  };

  return (
    <div className="d-flex flex-column full-height">
      {isWanEntityReady ? (
        <>
          <div>
            <SelectedEntity />
            <Connections />
            <WanName wanEntity={wanEntity} changeWanEntityHandler={changeWanEntityHandler} />
            <NetworkSpeed
              networkSpeeds={networkSpeeds}
              entity={wanEntity}
              changeEntityHandler={changeWanEntityHandler}
            />
            <SubnetSize entity={wanEntity} changeEntityHandler={changeWanEntityHandler} />
          </div>
          <div className="architecture-sticky-button-wrapper mt-3">
            <Button className="btn-violet w-100" onClick={() => updateScriptHandler()} disabled={isUpdating}>
              {isUpdating && (
                <span className="spinner-border spinner-border-sm mb-1 mr-2" role="status" aria-hidden="true" />
              )}
              Update script
            </Button>
          </div>
        </>
      ) : (
        <span>Loading...</span>
      )}
    </div>
  );
};

export default WanForm;
