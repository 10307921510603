import React, { useContext, useEffect, useState } from 'react';
import { ArchitectureContext } from '../../../context/ArchitectureContext';
import { Button } from 'reactstrap';
import log from 'eslint-plugin-react/lib/util/log';
import Select from 'react-select';
import ServerIcon from '../../../../../../images/cloudPilot/server-icon.svg';
import FirewallIcon from '../../../../../../images/firewall.svg';
import LoadBalancerIcon from '../../../../../../images/load_balancer.svg';
import WanIcon from '../../../../../../images/cloudPilot/wan-icon.svg';
import LanIcon from '../../../../../../images/cloudPilot/lan-icon.svg';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import axios from '../../../../../../axiosInstance';

const Connections = () => {
  const [isAddConnectionActive, setIsAddConnectionActive] = useState(false);
  const [allowedConnections, setAllowedConnections] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState({});
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  const { architectureDetails, entityData, templatesDetails, fetchDetails } = useContext(ArchitectureContext);

  useEffect(() => {
    if (entityData) {
      const connections = templatesDetails.find(entity => entity.node === entityData.type).allowedParentNodes;
      setAllowedConnections(connections);
    }
  }, [entityData]);

  const entity = architectureDetails.components.find(entity => entity.id === entityData.id);

  const iconMap = {
    internet: ServerIcon,
    device: ServerIcon,
    firewall: FirewallIcon,
    loadBalancer: LoadBalancerIcon,
    wan: WanIcon,
    lan: LanIcon,
  };

  const handleConnect = () => {
    axios
      .post(`/api/user/${tenant}/architectures/${id}/connect`, {
        fromNodeId: entityData.id,
        toNodeId: selectedConnection.value,
      })
      .then(res => {
        if (res.status === 200) {
          setIsAddConnectionActive(false);
          setSelectedConnection({});
          fetchDetails();
        }
      })
      .catch(e => {
        console.error(e);
      });
  };

  const handleDisconnect = nodeid => {
    axios
      .post(`/api/user/${tenant}/architectures/${id}/disconnect`, {
        fromNodeId: entityData.id,
        toNodeId: nodeid,
      })
      .then(res => {
        if (res.status === 200) {
          setIsAddConnectionActive(false);
          setSelectedConnection({});
          fetchDetails();
        }
      })
      .catch(e => {
        console.error(e);
      });
  };

  return (
    <div className="architecture-form-block d-flex bg-white rounded w-100 p-3 align-items-center mb-3">
      <div className="w-100">
        <p className="font-size-lg bold mb-1 w-100">
          Connections for {entityData.type} "{entityData.name}"
        </p>
        {entity.parentIds.map(id => (
          <div key={id} className="d-flex justify-content-between align-items-center w-100 mb-1 ml-1">
            <div className="d-flex">
              <img
                className="width-px-20 mr-1"
                src={iconMap[architectureDetails.components.find(entity => entity.id === id).type]}
                alt="selected-device"
              />
              <p className="mb-0">{architectureDetails.components.find(entity => entity.id === id).name}</p>
              <span className="mx-2">-></span>
              <img className="width-px-20 mr-1" src={iconMap[entityData.type]} alt="selected-device" />
              <p className="mb-0">{entityData.name}</p>
            </div>
            <Button className="btn-danger btn-sm w-25 mr-1" onClick={() => handleDisconnect(id)}>
              Disconnect
            </Button>
          </div>
        ))}
        {entity.childrenIds.map(id => (
          <div key={id} className="d-flex justify-content-between align-items-center w-100 mb-1 ml-1">
            <div className="d-flex">
              <img className="width-px-20 mr-1" src={iconMap[entityData.type]} alt="selected-device" />
              <p className="mb-0">{entityData.name}</p>
              <span className="mx-2">-></span>
              <img
                className="width-px-20 mr-1"
                src={iconMap[architectureDetails.components.find(entity => entity.id === id).type]}
                alt="selected-device"
              />
              <p className="mb-0">{architectureDetails.components.find(entity => entity.id === id).name}</p>
            </div>
            <Button className="btn-danger btn-sm w-25 mr-1" onClick={() => handleDisconnect(id)}>
              Disconnect
            </Button>
          </div>
        ))}
        {isAddConnectionActive && (
          <div className="d-flex justify-content-between w-100">
            <Select
              className="w-75"
              data-cy="add-connection"
              options={architectureDetails.components
                .filter(
                  entity =>
                    entity.id !== entityData.id &&
                    allowedConnections.includes(entity.type) &&
                    !entity.parentIds.includes(entityData.id) &&
                    !entity.childrenIds.includes(entityData.id)
                )
                .map(entity => ({
                  label: (
                    <>
                      <img className="width-px-20 mr-1" src={iconMap[entity.type]} alt="selected-device" />
                      {entity.name}
                    </>
                  ),
                  value: entity.id,
                }))}
              lavue={selectedConnection}
              onChange={val => setSelectedConnection(val)}
            />
            <Button className="btn-success btn-sm ml-2 w-25" onClick={handleConnect}>
              Connect
            </Button>
          </div>
        )}
        {!isAddConnectionActive && (
          <Button className="btn-success btn-sm mt-2" onClick={() => setIsAddConnectionActive(true)}>
            Add connection
          </Button>
        )}
      </div>
    </div>
  );
};

export default Connections;
