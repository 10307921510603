import React, { useContext, useEffect, useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import clsx from 'clsx';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import axios from '../../../../../axiosInstance';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';

const ModalDeletingEntity = () => {
  const [isHasChildren, setIsHasChildren] = useState(false);
  const [deletingOption, setDeletingOption] = useState('delete');
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [nodeForTransfer, setNodeForTransfer] = useState('');
  const {
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setIsEntityChanged,
    entityData,
    setEntityData,
    architectureDetails,
    fetchDetails,
  } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  const toggleModal = () => {
    setDeletingOption('delete');
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  useEffect(() => {
    if (architectureDetails?.components && entityData) {
      setIsHasChildren(architectureDetails.componentLinks.some(link => link.fromComponentId === entityData.id));

      const filtered = architectureDetails.components
        .filter(component => component.type === entityData.type && component.id !== entityData.id)
        .map(component => ({
          id: component.id,
          name: component.name,
        }));
      setFilteredComponents(filtered);
    }
  }, [entityData, architectureDetails]);

  const deleteModalHandler = () => {
    if (deletingOption === 'delete') {
      axios
        .delete(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}`)
        .then(res => {
          setIsEntityChanged(false);
          toggleModal();
          setEntityData(null);
          fetchDetails();
        })
        .catch(e => {
          console.warn(e.message);
        });
      return;
    }

    if (deletingOption === 'transfer' && nodeForTransfer) {
      const transferedNodeId = architectureDetails.componentLinks.find(
        component => component.fromComponentId === entityData.id
      ).toComponentId;

      axios
        .patch(`/api/user/${tenant}/architectures/${id}/node/${transferedNodeId}/transfer`, {
          parentNodeId: nodeForTransfer,
        })
        .then(() => {
          return axios.delete(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}`);
        })
        .then(() => {
          setIsEntityChanged(false);
          toggleModal();
          fetchDetails();
        })
        .catch(e => {
          console.warn(e.message);
        });
    }
  };

  return (
    <Modal isOpen={isDeleteModalOpen}>
      <ModalHeader toggle={toggleModal}>
        Delete {entityData?.type} {entityData?.name}?
      </ModalHeader>
      <ModalBody>
        {isHasChildren ? (
          <>
            <p>This {entityData?.type} has dependent nodes. Choose your option:</p>
            <FormGroup className="d-flex align-items-center mb-0">
              <input
                data-cy="option-delete"
                type="radio"
                id="option-delete"
                name="option-delete"
                onChange={() => setDeletingOption('delete')}
                checked={deletingOption === 'delete'}
              />
              <label className="py-2 mb-0" htmlFor="option-delete">
                Delete anyway
                <p className="my-0 font-weight-normal text-muted">
                  Delete this {entityData?.type} and all dependent nodes
                </p>
              </label>
            </FormGroup>
            <FormGroup className="d-flex align-items-center mb-0">
              <input
                data-cy="option-transfer"
                type="radio"
                id="option-transfer"
                name="option-transfer"
                onChange={() => setDeletingOption('transfer')}
                checked={deletingOption === 'transfer'}
                disabled={filteredComponents.length === 0}
              />
              <label className="py-2 mb-0" htmlFor="option-transfer">
                Transfer dependent nodes
                {filteredComponents.length === 0 ? (
                  <p className="my-0 font-weight-normal text-muted">
                    You don't have other {entityData?.type} for transferring
                  </p>
                ) : (
                  <p className="my-0 font-weight-normal text-muted">
                    Transfer dependent nodes to other {entityData?.type} and delete this {entityData?.type}
                  </p>
                )}
              </label>
            </FormGroup>
          </>
        ) : (
          <p>Are you sure you want to delete {entityData?.type}?</p>
        )}
        {deletingOption === 'transfer' && (
          <FormGroup>
            <Label for="transferNode">Please select {entityData?.type} by name:</Label>
            <Input
              type="select"
              name="transferNode"
              id="transferNode"
              className="form-control"
              onChange={e => {
                setNodeForTransfer(e.target.value);
              }}
              value={entityData?.transferNode}
            >
              <option value="">Select node</option>
              {filteredComponents.map(component => (
                <option key={component.id} value={component.id}>
                  {component.name}
                </option>
              ))}
            </Input>
            <FormFeedback>Please select {entityData?.type} by name</FormFeedback>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button data-cy="architecture-modal-button-cancel" type="button" className="btn-light" onClick={toggleModal}>
          Cancel
        </Button>
        <Button data-cy="architecture-modal-button" type="submit" color="danger" onClick={deleteModalHandler}>
          {isHasChildren ? 'Apply' : 'Delete'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDeletingEntity;
