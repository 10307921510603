import React, { createContext, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import axios from '../../../../axiosInstance';
import { AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';

const ArchitectureContext = createContext({});

const ArchitectureProvider = ({ children, location }) => {
  const [architectureDetailsFetching, setArchitectureDetailsFetching] = useState(false);
  const [architectureDetails, setArchitectureDetails] = useState(null);
  const [templatesDetails, setTemplatesDetails] = useState([]);
  const [isEntityOpen, setIsEntityOpen] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [entityData, setEntityData] = useState(null);
  const [isEntityChanged, setIsEntityChanged] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isEntityLogsModalOpen, setIsEntityLogsModalOpen] = useState(false);
  const [nodeButtonGroupId, setNodeButtonGroupId] = useState('');
  const [nodeAddModalId, setNodeAddModalId] = useState('');
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  const fetchDetails = async () => {
    setArchitectureDetailsFetching(true);

    try {
      const res = await axios.get(`api/user/${tenant}/architectures/${id}`);

      if (res.data) {
        const details = {
          id: res.data.identifier || '',
          status: res.data.status || '',
          provider: res.data.provider || '',
          cloudId: res.data.cloudId || '',
          cloudName: res.data.cloudName || '',
          components: [],
          componentLinks: [],
        };

        let linkId = 1;

        (res.data.components || []).forEach(component => {
          details.components.push({
            id: component.identifier || '',
            name: component.data?.name || '',
            type: component.type || '',
            status: component.status || '',
            data: component.data || {},
            parentIds: component.parentIds || [],
            childrenIds: component.childrenIds || [],
          });

          if (Array.isArray(component.parentIds)) {
            component.parentIds.forEach(parentId => {
              details.componentLinks.push({
                fromComponentId: parentId,
                toComponentId: component.identifier,
                id: linkId++,
              });
            });
          }
        });

        setArchitectureDetails(details);
      }
    } catch (error) {
      console.error('Failed to fetch architecture details:', error);
    } finally {
      setArchitectureDetailsFetching(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchTemplateDetails = async () => {
    axios
      .get('/api/user/architecture-dependencies', {
        params: { provider: 'xelon' },
      })
      .then(res => {
        setTemplatesDetails(res.data);
      });
  };

  useEffect(() => {
    fetchTemplateDetails();
  }, []);

  const getTemplateAllowedChildren = componentType => {
    if (templatesDetails.length > 0) {
      const component = templatesDetails.find(item => item.node === componentType);

      return component ? component.allowedChildNodes : [];
    }

    return [];
  };

  const getTemplateAllowedParents = componentType => {
    if (templatesDetails.length > 0) {
      const component = templatesDetails.find(item => item.node === componentType);

      return component ? component.allowedParentNodes : [];
    }

    return [];
  };

  const handleCloseEntity = () => {
    if (isSummaryOpen && (nodeButtonGroupId !== '' || nodeAddModalId !== '')) {
      setNodeButtonGroupId('');
      setNodeAddModalId('');
      if (isEntityChanged && !isCloseModalOpen) {
        setIsCloseModalOpen(true);
        return;
      }
      setIsEntityOpen(false);
    } else {
      setNodeButtonGroupId('');
      setNodeAddModalId('');
      if (isEntityChanged && !isCloseModalOpen) {
        setIsCloseModalOpen(true);
        return;
      }
      setIsEntityOpen(false);
      setIsSummaryOpen(false);
    }
  };

  return (
    <ArchitectureContext.Provider
      value={{
        architectureDetails,
        fetchDetails,
        isEntityOpen,
        setIsEntityOpen,
        isSummaryOpen,
        setIsSummaryOpen,
        entityData,
        setEntityData,
        isEntityChanged,
        setIsEntityChanged,
        isCloseModalOpen,
        setIsCloseModalOpen,
        isDeleteModalOpen,
        setIsDeleteModalOpen,
        isTransferModalOpen,
        setIsTransferModalOpen,
        getTemplateAllowedChildren,
        getTemplateAllowedParents,
        templatesDetails,
        nodeButtonGroupId,
        setNodeButtonGroupId,
        handleCloseEntity,
        nodeAddModalId,
        setNodeAddModalId,
        isEntityLogsModalOpen,
        setIsEntityLogsModalOpen,
      }}
    >
      {children}
    </ArchitectureContext.Provider>
  );
};

export default withRouter(ArchitectureProvider);
export { ArchitectureContext };
