import React, { useContext, useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { ArchitectureContext } from '../context/ArchitectureContext';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import axios from '../../../../axiosInstance';

export const ArchitectureCode = () => {
  const [selected, setSelected] = useState('');
  const [code, setCode] = useState('');
  const [code2, setCode2] = useState('');
  const { architectureDetails } = useContext(ArchitectureContext);
  const { id } = useParams();
  const { tenant } = useContext(AuthContext);

  useEffect(() => {
    if (architectureDetails) {
      setSelected(architectureDetails.components[0].id);
      setCode(JSON.stringify(architectureDetails.components[0].data, null, 2));
    }
  }, [architectureDetails]);

  const clickFileHandler = component => {
    setSelected(component.id);
    setCode(JSON.stringify(component.data, null, 2));
  };

  const fetchArchitectureCode = () => {
    axios
      .get(`/api/user/${tenant}/architectures/${id}/code`)
      .then(res => {
        setCode(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchArchitectureCode();
  }, []);

  return (
    architectureDetails && (
      <div className="container mt-4">
        <div className="mt-4 pt-4">
          <div className="d-flex justify-content-between overflow-auto">
            <div className="px-4 py-3 w-100 bg-soft-blue border-bottom border-width-3 border-blue-dark">
              terraform.tf
            </div>
            {/*{architectureDetails.components.map(component => (*/}
            {/*  <div*/}
            {/*    key={component.id}*/}
            {/*    className={clsx(*/}
            {/*      'px-4 py-3 w-100 cursor-pointer',*/}
            {/*      selected === component.id ? 'bg-soft-blue border-bottom border-width-3 border-blue-dark' : 'bg-white'*/}
            {/*    )}*/}
            {/*    onClick={() => clickFileHandler(component)}*/}
            {/*  >*/}
            {/*    {component.name}.js*/}
            {/*  </div>*/}
            {/*))}*/}
          </div>
          <div className="architecture-code-editor-wrapper">
            <Editor height="80vh" language="hcl" value={code} />
          </div>
        </div>
      </div>
    )
  );
};

export default ArchitectureCode;
