import React, { useContext, useEffect, useState } from 'react';
import { cloudId } from '../../constants';
import axios from '../../../../../axiosInstance';
import { defaultNetworkSpeed } from '../../../../../utils/constants';
import SelectedEntity from './formComponents/SelectedEntity';
import LanNameType from './formComponents/Network/LanNameType';
import NetworkSpeed from './formComponents/Network/NetworkSpeed';
import NetworkSettings from './formComponents/Network/NetworkSettings';
import DnsServers from './formComponents/Network/DnsServers';
import { Button } from 'reactstrap';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import { v4 as uuidv4 } from 'uuid';
import { PushNotificationContext } from '../../../../contexts/PushNotificationContext';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Connections from './formComponents/Connections';

const LanForm = () => {
  const [lanEntity, setLanEntity] = useState({});
  const [networkSpeeds, setNetworkSpeeds] = useState([]);
  const [isLanEntityReady, setIsLanEntityReady] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { entityData, setIsEntityChanged, fetchDetails, setIsEntityOpen } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  useEffect(() => {
    if (entityData) {
      setLanEntity({
        name: entityData?.data?.name || '',
        network: entityData?.data?.network || '',
        subnetSize: entityData?.data?.subnetSize || 29,
        gateway: entityData?.data?.gateway || '',
        dnsServer1: entityData?.data?.dnsServer1 || '',
        dnsServer2: entityData?.data?.dnsServer2 || '',
        currentSpeed: entityData?.data?.currentSpeed || '10000000000',
        isStretched: entityData?.data?.isStretched || false,
        cloudForStretching: entityData?.data?.cloudForStretching || 10,
      });
      setIsLanEntityReady(true);
    }
  }, [entityData]);

  const fetchNetworkSpeeds = () => {
    const payload = {
      cloudId,
      type: 'LAN',
      is_stretched: lanEntity.isStretched,
    };
    axios.post('/api/user/network-speeds', payload).then(res => {
      setNetworkSpeeds(res.data);
      const speedValue =
        res.data.find(obj => obj['value'] === defaultNetworkSpeed.WAN)?.value ??
        Math.min(...res.data.map(obj => obj.value));
      changeLanEntityHandler('currentSpeed', speedValue);
    });
  };

  useEffect(() => {
    if (isLanEntityReady) {
      fetchNetworkSpeeds();
    }
  }, [isLanEntityReady]);

  const changeLanEntityHandler = (key, value) => {
    const lanEntityCopy = { ...lanEntity };
    lanEntityCopy[key] = value;
    setLanEntity(lanEntityCopy);
  };

  const updateScriptHandler = async () => {
    setIsUpdating(true);

    axios
      .put(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}`, { type: 'lan', data: lanEntity })
      .then(res => {
        setIsUpdating(false);
        setIsEntityChanged(false);
        setIsEntityOpen(false);
        fetchDetails();
      })
      .catch(e => {
        setIsUpdating(false);
        console.warn(e.message);
      });
  };

  return (
    <div className="d-flex flex-column full-height">
      {isLanEntityReady ? (
        <>
          <div>
            <SelectedEntity />
            <Connections />
            <LanNameType lanEntity={lanEntity} changeLanEntityHandler={changeLanEntityHandler} />
            <NetworkSpeed
              networkSpeeds={networkSpeeds}
              entity={lanEntity}
              changeEntityHandler={changeLanEntityHandler}
            />
            <NetworkSettings entity={lanEntity} changeEntityHandler={changeLanEntityHandler} />
            <DnsServers entity={lanEntity} changeEntityHandler={changeLanEntityHandler} />
          </div>
          <div className="architecture-sticky-button-wrapper mt-3">
            <Button className="btn-violet w-100" onClick={() => updateScriptHandler()} disabled={isUpdating}>
              {isUpdating && (
                <span className="spinner-border spinner-border-sm mb-1 mr-2" role="status" aria-hidden="true" />
              )}
              Update script
            </Button>
          </div>
        </>
      ) : (
        <span>Loading...</span>
      )}
    </div>
  );
};

export default LanForm;
